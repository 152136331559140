export const inPerson = async (data) => {
  const markdown = [
    "<h1>In-Person Event Submission</h1>",
    "<h2>Requirements</h2>",
    "<ul>",
    `<li>Event Name: ${data.nameIP}</li>`,
    `<li>Link to the Event Website: ${data.linkwebIP}</li>`,
    `<li>Are you an organizer of this event? ${data.organizerIP}</li>`,
    "</ul>",
    "<h2>Event Demographics</h2>",
    "<ul>",
    `<li>[${
      data.EDIP ? "x" : " "
    }] This event commits to Event Diversity and Inclusion.</li>`,
    "<li><code>Q</code> Detail the process for measuring Event Demographics.</li>",
    `<li><code>A</code> ${data.EDIP1}</li>`,
    "<li><code>Q</code> Provide an example of an opt-out option on the Event registration page if available.</li>",
    `<li><code>A</code> ${data.EDIP2}</li>`,
    "<li><code>Q</code> Provide an example of a demographics text input box on the Event registration page if available.</li>",
    `<li><code>A</code> ${data.EDIP3}</li>`,
    "</ul>",
    "<h2>Inclusive Experience at Event</h2>",
    "<ul>",
    `<li>[${
      data.IEEIP ? "x" : " "
    }] This event commits to the Code of Conduct at Event.</li>`,
    "<li><code>Q</code> Provide an example of the Event Feedback page if available.</li>",
    `<li><code>A</code> ${data.IEEIP1}</li>`,
    "<li><code>Q</code> Is the event team using feedback from previous event's attendees, speakers, and volunteers to improve DEI at this event?</li>",
    `<li><code>A</code> ${data.IEEIP2}</li>`,
    "<li><code>Q</code> Does the event team plan to use feedback from this event's attendees, speakers, and volunteers to improve DEI at future events?</li>",
    `<li><code>A</code> ${data.IEEIP3}</li>`,
    "<li><code>Q</code> How can attendees learn more about accessibility at the event?</li>",
    `<li><code>A</code> ${data.IEEIP4}</li>`,
    "<li><code>Q</code> Does the event platform allow attendees to suggest future accomodations for the event?</li>",
    `<li><code>A</code> ${data.IEEIP5}</li>`,
    "<li><code>Q</code> Will the event platform be accessible to attendees and speakers after the event?</li>",
    `<li><code>A</code> ${data.IEEIP6}</li>`,
    "</ul>",
    "<h2>Code of Conduct at Event</h2>",
    "<ul>",
    `<li>[${
      data.COCIP ? "x" : " "
    }] This event commits to the Code of Conduct at Event.</li>`,
    "<li><code>Q</code> Is the code of conduct posted at Event venue?</li>",
    `<li><code>A</code> ${data.COCIP1}</li>`,
    "<li><code>Q</code> Provide a link for the Event Code of Conduct.</li>",
    `<li><code>A</code> ${data.COCIP2}</li>`,
    "</ul>",
    "<h2>Diversity Access Tickets</h2>",
    "<ul>",
    `<li>[${
      data.DATIP ? "x" : " "
    }] This event commits to the Diversity Access Tickets.</li>`,
    "<li><code>Q</code> How many different types of diversity access tickets are available for the event?</li>",
    `<li><code>A</code> ${data.DATIP1}</li>`,
    "<li><code>Q</code> What are the criteria for qualifying for a diversity access ticket?</li>",
    `<li><code>A</code> ${data.DATIP2}</li>`,
    "<li><code>Q</code> Provide a link to the page containing information about Diversity Access Tickets.</li>",
    `<li><code>A</code> ${data.DATIP3}</li>`,
    "</ul>",
    "<h2>Family Friendliness</h2>",
    "<ul>",
    `<li>[${
      data.FFIP ? "x" : " "
    }] This event commits to Family Friendliness.</li>`,
    "<li><code>Q</code> Does the Event provide childcare facilities for its attendees and speakers?</li>",
    `<li><code>A</code> ${data.FFIP1}</li>`,
    "<li><code>Q</code> What are the other ways that a family-friendly environment is being created in the Event?</li>",
    `<li><code>A</code> ${data.FFIP2}</li>`,
    "<li><code>Q</code> Provide relevant links related to family friendliness at the Event.</li>",
    `<li><code>A</code> ${data.FFIP3}</li>`,
    "</ul>",
    "<h2>Event Accessibility</h2>",
    "<ul>",
    `<li>[${
      data.EAIP ? "x" : " "
    }] This event commits to Event Accessibility.</li>`,
    "<li><code>Q</code> Is the Event in a wheelchair-accessible venue?</li>",
    `<li><code>A</code> ${data.EAIP1}</li>`,
    "<li><code>Q</code> Are speakers given guidance about creating slides that are colorblind accessible?</li>",
    `<li><code>A</code> ${data.EAIP2}</li>`,
    "<li><code>Q</code> Is signage at the event and the event website colorblind accessible?</li>",
    `<li><code>A</code> ${data.EAIP3}</li>`,
    "<li><code>Q</code> Does the Event provide other accessibility accommodations, or will upon request?</li>",
    `<li><code>A</code> ${data.EAIP4}</li>`,
    "<li><code>Q</code> Provide relevant links related to event accessibility at the Event.</li>",
    `<li><code>A</code> ${data.EAIP5}</li>`,
    "</ul>",
    "<h2>Event Location Inclusivity</h2>",
    "<ul>",
    `<li>[${
      data.ELIIP ? "x" : " "
    }] This event commits to Event Location Inclusivity.</li>`,
    "<li><code>Q</code> Has the Event's location been checked against lists of places of concern for the following demographics: sexual and gender minorities, people with disabilities, racial and ethnic minorities, women, or religious minorities?</li>",
    `<li><code>A</code> ${data.ELIIP1}</li>`,
    "<li><code>Q</code> Have the Event's dates been checked for other events happening in the same location at the same time that could potentially bring harm to a subset of any attendees?</li>",
    `<li><code>A</code> ${data.ELIIP2}</li>`,
    "<li><code>Q</code> The Event website addresses or acknowledges any cause for concern for marginalized attendees.</li>",
    `<li><code>A</code> ${data.ELIIP3}</li>`,
    "</ul>",
    "<h2>Public Health and Safety</h2>",
    "<ul>",
    `<li>[${
      data.PHIP ? "x" : " "
    }] This event commits to Public Health and Safety.</li>`,
    "<li><code>Q</code> Have you participated in the Public Health Pledge Badging Program?</li>",
    `<li><code>A</code> ${data.PHIP1}</li>`,
    "<li><code>Q</code> Provide a link to the PHPledge Badges received.</li>",
    `<li><code>A</code> ${data.PHIP2}</li>`,
    "<li><code>Q</code> Provide a link to publicly available information about public health and safety on the event website.</li>",
    `<li><code>A</code> ${data.PHIP3}</li>`,
    "</ul>",
  ].join("");

  return markdown;
};
