const WhatIsDeiBadging = () => {
  return (
    <div className="what__is__dei">
      <h2 id="what-is-dei-project-badging">What is Project Badging?</h2>
      <div>
        <p>
          CHAOSS DEI Project Badging is an initiative developed by the CHAOSS
          project to recognize open source projects that exemplify best
          practices for diversity, equity, and inclusion (DEI) work within their
          respective communities. The initiative uses CHAOSS DEI metrics as a
          benchmark to reflect on DEI efforts in an open source project. All
          CHAOSS DEI metrics are developed in an open and collaborative setting.
        </p>
      </div>
      <div className="objectives">
        <h3>Project Badging Objective</h3>
        <p>
          The primary objective of CHAOSS DEI Project Badging is to enable open
          source members to signal their ongoing efforts in improving and
          prioritizing DEI within their communities.
        </p>
        <ul>
          <li>To assess and address DEI efforts in open source projects</li>
          <li>Recognize projects and communities for their DEI efforts</li>
          <li>
            Enable organizations to make informed decisions and take thoughtful
            actions to create a more inclusive and equitable environment
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhatIsDeiBadging;
